import Template from "images/five-card-layout/template.svg";
import Expertise from "images/five-card-layout/expertise.svg";
import Security from "images/five-card-layout/security.svg";
import AI from "images/five-card-layout/AI.svg";

export const fiveCardLayoutData = {
  firstCard: {
    title: "Say No to ETL",
    description:
      "Go beyond traditional ETL constraints. Create and control your own native integrations to foster trust and presence on the Salesforce AppExchange marketplace.",
    buttonName: "Join the Waitlist",
    buttonLink: "/joinWaitlist",
  },
  otherCards: [
    {
      title: "Expertise Unneeded",
      description:
        "Our user-friendly drag-and-drop interface and ready-to-use templates enable effortless native integration, bypassing the need for Salesforce development skills.",
      svgLink: Expertise,
    },
    {
      title: "Template Power",
      description:
        "Select, customize, and go live. Our pre-built templates streamline your path to the AppExchange, making complex integrations a thing of the past.",
      svgLink: Template,
    },
    {
      title: "Security Success",
      description: `Security and compliance made simple. Build with confidence using our platform, and watch your integrations pass Salesforce's security review on the first try.`,
      svgLink: Security,
    },
    {
      title: "AI-Powered",
      description: `Streamline your integration with our AI-driven platform. Seamlessly generate and customize Salesforce-native solutions.`,
      svgLink: AI,
    },
  ],
};
