import React from "react";
import PropTypes from "prop-types";
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import ReusableButton from "./ReusableButton";

const OTBIntegration = ({ subtitle, title, description, image, buttonLink, buttonName }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container
      sx={{
        py: 8,
      }}
    >
      <Grid container spacing={4} direction={"row-reverse"} justifyContent={"center"} alignItems={"center"}>
        {/* Image section (right) */}
        <Grid
          data-aos={isMobile ? "fade-up" : "fade-left"}
          data-aos-once={false}
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
        >
          <Box
            alt={image.altText}
            component={"img"}
            height={1}
            width={{ xs: "80%", md: 1 }}
            minHeight={300}
            src={image.imageSrc}
            loading="lazy"
          />
        </Grid>
        {/* Text and button section (left) */}
        <Grid item xs={12} md={6} data-aos={isMobile ? "fade-up" : "fade-right"} data-aos-once={false}>
          <Box
            textAlign={{ xs: "center", md: "start" }}
            sx={{
              margin: { xs: "0 auto 24px auto", md: "0 0 24px 0" },
              width: { xs: 1, md: "90%" },
            }}
          >
            <Typography variant="description" color={"primary.main"} mb={2} gutterBottom>
              {subtitle}
            </Typography>
            <Typography sx={{ mb: 4 }} variant={"h2"} gutterBottom>
              {title}
            </Typography>
            <Typography variant={"subtitle2"} textAlign={"start"} component={'p'}>
              {description}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
            }}
          >
            <ReusableButton title={buttonName} link={buttonLink} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

OTBIntegration.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.shape({
    imageSrc: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,
  }).isRequired,
  buttonLink: PropTypes.string,
  buttonName: PropTypes.string,
};

export default OTBIntegration;
