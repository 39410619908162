import React, { useState } from "react";
import { Typography, Box, Modal, Button } from "@mui/material";
import homeDemoImage from "images/image-video-modal/HomeDemo.webp";
import playIcon from "images/image-video-modal/play-icon.png";
import bgUrl from "images/hero-header/staticBackground.svg";
import Container from "./Container";

const VideoModal = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ backgroundImage: `url(${bgUrl})` }} width={'100%'}>
      <Container sx={{ pt: "0 !important" }}>
        <Box sx={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}>
          {/* Home Demo Image */}
          <Box
            component={"img"}
            src={homeDemoImage}
            alt="Appnigma AI preview image"
            sx={{
              maxWidth: "1171px",
              margin: "auto",
              mb: "-7%",
              objectFit: "cover",
            }}
            loading="lazy"
            width={"85%"}
            height={"30%"}
          />
          {/* Button */}
          <Box
            sx={{
              position: "absolute",
              top: { xs: "60%", lg: "45%" },
              left: "88%",
              transform: "translate(-50%, -50%)",
              zIndex: 2,
              backgroundColor: "#ECEDEF",
              paddingX: 0,
              width: { xs: "6.5rem", sm: "9.5rem", md: "13.5rem" },
              height: { xs: "3rem", sm: "4.4rem", md: "5.5rem" },
              borderRadius: "3.5rem",
              border: "3px solid #2C85FF",
              transition: "transform 0.3s ease", // Add transition for smooth movement
              "&:hover": {
                transform: "translate(-50%, -50%) scale(1.05)", // Scale the button slightly on hover
              },
            }}
          >
            <Button
              onClick={handleOpen}
              sx={{
                borderRadius: "3rem",
                backgroundColor: "white",
                display: "flex",
                flexDirection: "row",
                gap: "0.65rem",
                margin: { xs: "0.2rem", sm: "0.5rem" },
                width: { xs: "5.75rem", sm: "8rem", md: "12rem" },
                height: { xs: "2.25rem", sm: "3rem", md: "4rem" },
                transition: "background-color 0.3s ease", // Add transition for smooth color change
                "&:hover": {
                  backgroundColor: "#F3F4F6", // Change background color on hover
                },
              }}
            >
              <Box
                component={"img"}
                src={playIcon}
                loading="lazy"
                alt="Appnigma AI preview Video Play Icon"
                sx={{
                  width: "3rem",
                  height: "3rem",
                }}
              />
              <Typography
                color="#2C85FF"
                sx={{
                  fontSize: { xs: "6px", sm: "10px", md: "1rem" },
                  fontWeight: "bold",
                }}
              >
                Watch Video
              </Typography>
            </Button>
          </Box>
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="video-modal"
          aria-describedby="video-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100vw",
              height: "100vh",
              maxWidth: "70vw",
              maxHeight: "80vh",
              backgroundColor: "white",
              outline: "none",
              overflow: "hidden",
            }}
          >
            <iframe
              title="Embedded Video"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/0uYUeS1udrU"
              frameBorder="0"
              allowFullScreen
              style={{ border: "none" }}
            ></iframe>
          </div>
        </Modal>
      </Container>
    </Box>
  );
};

export default VideoModal;
